import axios, { AxiosResponse } from 'axios';
import IComandResult from '@/Model/IComandResultTyped';
import useAuthToken from '@/utillities/useAuthToken';
import { ref } from 'vue';
import UserNotificationViewModel from '@/Model/UserNotificationViewModel';
import IPagedCommandResult, { PagedModel } from '@/Model/IPagedCommandResult';
import { appState } from '.';
export default (): any => {
  const logs = ref<Array<UserNotificationViewModel>>();
  const pageModel = ref<PagedModel>();
  const getUnread = async (): Promise<number> => {
    await useAuthToken();
    return new Promise<number>((resolve, reject) => {
      axios
        .get(`/api/notification/user/unread`)
        .then((response: AxiosResponse<IComandResult<number>>) => {
          if (response.status == 200) {
            if (response.data.success) {
              resolve(response.data.data);
            } else {
              reject(response.data.errors);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getLogs = async (
    page: number = 1,
    size: number = 100
  ): Promise<Array<UserNotificationViewModel>> => {
    await useAuthToken();
    return new Promise<Array<UserNotificationViewModel>>((resolve, reject) => {
      axios
        .get(`/api/notification/user/logs?page=${page}&size=${size}`)
        .then(
          (
            response: AxiosResponse<
              IPagedCommandResult<Array<UserNotificationViewModel>>
            >
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                logs.value = response.data.data;
                const page = new PagedModel();
                page.page = response.data.page;
                page.size = response.data.size;
                page.total = response.data.total;
                pageModel.value = page;

                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const markLogsAsRead = async (): Promise<
    Array<UserNotificationViewModel>
  > => {
    await useAuthToken();
    return new Promise<Array<UserNotificationViewModel>>((resolve, reject) => {
      axios
        .put(`/api/notification/user/logs`)
        .then(
          (
            response: AxiosResponse<
              IPagedCommandResult<Array<UserNotificationViewModel>>
            >
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                logs.value = response.data.data;

                appState.updateMessageCount();
                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  return { logs, getUnread, getLogs, markLogsAsRead, pageModel };
};
