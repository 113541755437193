<template>
  <div>
    <TopMenu></TopMenu>
    <div class="container">
        <router-view />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import TopMenu from '@/components/TopMenu.vue';

import { configureOidc } from './services/auth.service';
import { updateAbility } from '@/plugins/AbilityPlugin';
import { appState } from '@/store';

export default defineComponent({
  components: {
    TopMenu
  },
  setup() {
    configureOidc().then((authService) => {
      updateAbility(authService.userProfile);
    });

    const errors = ref<Array<string>>();
    appState.$watch(
      (module) => module.errors,
      (systemErrors: string[] | string) => {
        errors.value = Array.isArray(systemErrors)
          ? systemErrors
          : [systemErrors];
        errors.value.forEach((error) => {
          console.error(error);
        });
      }
    );
    return { errors };
  }
});
</script>

<style lang="scss">
@import '@/style.scss';
</style>
