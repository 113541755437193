import { createStore, StoreOptions } from 'vuex';
import UserModule from '@/store/UserModule';
import AppModule from '@/store/AppModule';
import CustomerModule from '@/store/CustomerModule';
//
import IRootState from './IRootState';
import ErrorCorrectionModule from './ErrorCorrectionModule';
// register module (could be in any file)
const rootStore: StoreOptions<IRootState> = {};
const store = createStore<IRootState>(rootStore);
export const userModule = new UserModule({ store, name: 'user' });
export const appState = new AppModule({ store, name: 'app' });
export const customerState = new CustomerModule({ store, name: 'customer' });
export const errorCorrectionState = new ErrorCorrectionModule({
  store,
  name: 'error'
});

export default store;
